import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ConfigProvider } from "../../submodules/logictry_common/src/contexts/ConfigContext";
import Home from "../../containers/Home";
import DEFAULT_CONFIG from '../../submodules/logictry_common/src/config/AppConfig';
import Events from "../../containers/Events";
import About from "../../containers/About";
import Event from "../../containers/Event";
import EventYear from "../../containers/EventYear";
import CreateEditEvent from "../../containers/CreateEditEvent";
import Login from "../../containers/Login";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import Account from "../../containers/Account";
import ResetPassword from "../../containers/ResetPassword";
import Register from "../../containers/Register";
import EventAnalytics from "../../containers/EventAnalytics";
import years from "../../utils/years";
import Videos from "../../containers/Videos";

const history = new Set();
const AppRouter = () => {
  const { account } = useConfig();
  // Wait to load until config received in production
  const location = useLocation()
  useEffect(() => {
    if (!history.has(window.history.state.key)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant" as ScrollBehavior,
      });
    }
    history.add(window.history.state.key);
  }, [location]);
  if (!account) return null;
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1800}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <Routes key={window.location.href}>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        {years.map((year) => (
          <>
            <Route path={`/events/${year}/:id/edit`} element={<CreateEditEvent />} />
            <Route path={`/events/${year}/:id/analytics`} element={<EventAnalytics />} />
            <Route path={`/events/${year}/:id`} element={<Event />} />
            <Route path={`/events/${year}`} element={<EventYear />} />
          </>
        ))}
        <Route path="/events/create" element={<CreateEditEvent />} />
        <Route path="/events/:id" element={<Event />} />
        <Route path="/events/:id/edit" element={<CreateEditEvent />} />
        <Route path="/events/:id/analytics" element={<EventAnalytics />} />
        <Route path="/events" element={<Events />} />
        <Route path="/account" element={<Account />} />
        <Route path="/about" element={<About />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
};

const App = () => (
  <ConfigProvider defaultConfig={DEFAULT_CONFIG}>
    <Router>
      <Wrapper apiKey={"AIzaSyAdipzE4FB6pGLDZkHEjcohieMnW-sEll8"}>
        <AppRouter />
      </Wrapper>
    </Router>
  </ConfigProvider>
);

export default App;
