import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Map from "../../components/Map";
import Navbar from '../../components/Navbar/index';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CountDown from "../../components/Countdown";

import { useLocation } from "react-router-dom";
import { device } from "../../submodules/logictry_common/src/utils/device";
import useAppGet from "../../submodules/logictry_common/src/hooks/server/useAppGet";
import useAppQuery from "../../submodules/logictry_common/src/hooks/server/useAppQuery";
import useApiGet from "../../submodules/logictry_common/src/hooks/server/useApiGet";
import useApiQuery from "../../submodules/logictry_common/src/hooks/server/useApiQuery";
import WysiwygWidget from '../../submodules/logictry_wysiwyg/src/js';
import ChangeableImage from "../../submodules/logictry_common/src/components/ChangeableImage";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import AppConstants from "../../submodules/logictry_config/s3_config.json";
import { EVENTS_COLLECTION } from "../../collections";
import useApiPatch from "../../submodules/logictry_common/src/hooks/server/useApiPatch";
import useApiPost from "../../submodules/logictry_common/src/hooks/server/useApiPost";

import Button from "../../styledhtml/Button";
import { toast } from "react-toastify";
import Select from "../../submodules/logictry_common/src/components/Select";
import Popup from "../../submodules/logictry_common/src/components/Popup";
import years from "../../utils/years";

const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia",
  "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium",
  "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria",
  "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad",
  "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czechia",
  "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor (Timor-Leste)", "Ecuador", "Egypt",
  "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
  "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
  "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
  "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea", "Kosovo",
  "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
  "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
  "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique",
  "Myanmar (Burma)", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria",
  "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru",
  "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
  "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe",
  "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia",
  "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden",
  "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago",
  "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
  "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia",
  "Zimbabwe"
];

const Main = styled.main`
  #map {
    position: absolute;
    inset: 0;
  }
  h1 {
    text-align: center;
    margin: 8rem 0 4rem;
  }
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  header {
    p {
      font-size: 1.5rem;
    }
  }
  i {
    cursor: pointer;
  }
`;
const HeaderSection = styled.section`
  margin: 4rem auto 8rem;
  max-width: 800px;
  padding: 0 1rem;
  img {
    width: 100%;
  }
  > * {
    margin: 1rem auto;
  }
`;
const SponsorsAndPartners = styled.section`
  margin: 4rem auto 8rem;
  max-width: 800px;
  padding: 1rem;
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    > div {
      text-align: center;
      width: 180px;
      > img {
        object-fit: contain;
        width: 100%;
        max-height: 60px;
      }
      h3 {
        margin-top: 1rem;
      }
    }
  }
`;
const Program = styled.section`
  margin: 4rem auto 8rem;
  max-width: 800px;
  text-align: center;
  > div {
    border: 1px solid;
    padding: 2rem;
    border-radius: 0.5rem;
    margin: 3rem 0;
    position: relative;
    > div:nth-child(1) {
      position: absolute;
      top: 8px;
      left: 16px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    > div:nth-child(2) {
      position: absolute;
      top: 8px;
      right: 16px;
    }
    > * {
      margin: 1rem auto;
    }
  }
`;
const Speakers = styled.section`
  margin: 4rem auto 8rem;
  max-width: 600px;
  padding: 1rem;
  text-align: center;
`;
const Profiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin: auto;
  > div {
    border: 1px solid;
    border-radius: 0.5rem;
    padding: 2rem;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > div:nth-child(1) {
      position: absolute;
      top: 8px;
      left: 16px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    > div:nth-child(2) {
      position: absolute;
      top: 8px;
      right: 16px;
    }
    position: relative;
    > * {
      margin: 0.5rem auto;
    }
  }
`
const Location = styled.section`
  margin: 4rem auto 8rem;
  display: flex;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  iframe, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > div {
    height: 400px;
    width: 50%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    position: relative;
    > div {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      background-color: rgba(0,0,0,0.5);
      > div {
        font-size: 1rem;
      }
    }
  }
`;
const Register = styled.div`
  display: flex;
  justify-content: center;
  > a {
    text-decoration: none;
    > div {
      font-size: 1.2rem;
      margin: 2rem 0 0;
      padding: 0.75rem 2rem;
      text-align: center;
      border-radius: 2rem;
      display: block;
      border: 1px solid white;
      color: white;
      cursor:pointer;
      background-color: rgba(0,0,0,0.4);
    }
    > div:hover {
      background-color: white;
      color: black;
    } 
  }
`;
const Tickets = styled.section`
  margin: 4rem auto 8rem;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 680px) {
      flex-direction: column;
    }
    margin: 2rem;
    gap: 3rem;
    > div {
      padding: 1rem 2rem;
      box-shadow: 0 10px 25px 0 rgba(0,0,0, 0.1);
      border-radius: 0.5rem;
      max-width: 100%;
      width: 280px;
      > div:first-child {
        text-align: center;
        padding: 2rem 1rem;
        border-bottom: 1px solid gray;
        h3 {
          text-align: center;
          font-size: 3rem;
        }
      }
      > div:nth-child(2) {
        padding: 1rem;
        border-bottom: 1px solid gray;
        ul {
          padding-left: 1rem;
        }
        li {
          margin: 0.5rem 0;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
`;
const Info = styled.section`
  max-width: 800px;
  margin: 4rem auto 8rem;
  padding: 0 1rem;
`;
const CancelSave = styled.section`
  max-width: 800px;
  margin: 4rem auto 8rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
`;

const FAQ = styled.section`
  margin: 4rem auto 8rem;
  max-width: 680px;
  details {
    margin: 1rem 0;
    summary {
      cursor: pointer;
      span {
        margin-left: 0.5rem;
      }
    }
    > div {
      margin: 1rem 0 1rem 1.3rem;
    }
  }
`;

const DeleteWithConfirm = ({ onClick }) => {
  const [confirm, setConfirm] = useState(false);
  return (
    <>
      <div>
        <i className="fas fa-trash" onClick={() => setConfirm(true)}></i>
      </div>
      {confirm && <Popup onClose={() => { setConfirm(false); }}>
        <h2 style={{ textAlign: 'center' }}>Confirm delete</h2>
        <p style={{ textAlign: 'center' }}>Are you sure you want to delete?</p>
        <div style={{ display: 'flex' }}>
          <Button onClick={() => { setConfirm(false); }}>Cancel</Button>
          <Button onClick={() => { onClick(); setConfirm(false); }}>Delete</Button>
        </div>
      </Popup>}
    </>
  )
}

let wysiwygWidgets = {};
let geocoder;

const CreateEditEvent = () => {
  const { account } = useConfig();
  const [init, setInit] = useState(false);
  const [name, setName] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [externalUrl, setExternalUrl] = useState('');
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [image, setImage] = useState('');
  const [eventImage, setEventImage] = useState('');
  const [mapImage, setMapImage] = useState('');
  const [geo, setGeo] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [program, setProgram] = useState([]);
  const [maxGuests, setMaxGuests] = useState(0);
  const [year, setYear] = useState(parseInt(years[0]));
  const [url, setUrl] = useState('');
  const [externalGuests, setExternalGuests] = useState(0);
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const isCreating = paths[2] === 'create';
  const { query: events } = useApiQuery(EVENTS_COLLECTION, paths[4] === 'edit' ? [{ key: 'year', value: paths[2] }, { key: 'url', value: paths[3] }] : [{ key: '_id', value: paths[2] }], []);
  const event = events && events[0];
  const { setSaving, saveResults, setSaveResults } = useApiPost(EVENTS_COLLECTION);
  const { setUpdating, updateResults, setUpdateResults } = useApiPatch(EVENTS_COLLECTION, event && event._id);
  if (event && !init) {
    setInit(true);
    setName(event.name);
    setDescription(event.description || '');
    event.hosts && setHosts(event.hosts);
    event.program && setProgram(event.program);
    setStartTime(event.startTime);
    setEndTime(event.endTime);
    setTimeZone(event.timeZone);
    setAddress(event.address);
    setGeo(event.geo);
    setMaxGuests(event.maxGuests);
    setExternalGuests(event.externalGuests);
    setExternalUrl(event.externalUrl);
    setLocation(event.location);
    setCountry(event.country);
    setYear(event.year);
    setUrl(event.url);
    setImage(event.image);
    setEventImage(event.eventImage);
    setMapImage(event.mapImage);
  }
  const result = saveResults || updateResults;
  if (result) {
    setSaveResults(null);
    setUpdateResults(null);
    if (result._id) window.location.href = result.url ? `/events/${result.year}/${result.url}` : `/events/${result._id}`;
    if (result.errors && result.errors[0] && result.errors[0].path) toast.error(`The ${result.errors[0].path} is missing`);
  }
  const ids = event && event.hosts && event.hosts.map(({ _id }) => _id).filter((v) => v).join(',');
  const { query: users } = useAppQuery('users', ids && [{ key: 'ids', value: ids }], []);
  useEffect(() => {
    return () => {
      if (wysiwygWidgets) {
        Object.values(wysiwygWidgets).forEach((w) => w.unmount());
        wysiwygWidgets = {};
      }
    }
  }, []);
  const onRef = (mountNode, id, initialText, onTextChanged) => {
    if (wysiwygWidgets[id] || !mountNode) return;
    wysiwygWidgets[id] = new WysiwygWidget(mountNode, {
      onBlur: null,
      height: '400px',
      width: '100%',
      focusOnInit: null,
      fullscreen: true,
      disabled: false,
      initialText: description,
      onTextChanged,
      borderColor: 'black',
      borderRadius: 4
    });
  }

  if (!isCreating && !event) return null;
  if (!account || !account.loggedIn) {
    window.location.href = `${window.location.origin}/login`;
    return null;
  }
  if (event && !['5cf13c4899397a33fd191007', event.owner].includes(account._id)) {
    window.location.href = window.location.href.replace('/edit', '');
    return null;
  } else if (!event && !account._id) {
    window.location.href = `${window.location.origin}/login`;
    return null;
  }
  const s3Path = `652ec9546346c4180ae2c50b/${account._id}/`;
  const signingUrl = `/v1${AppConstants.S3.signingUrls.companyfiles}`;
  const timeZones = Intl.supportedValuesOf('timeZone');
  return (
    <Main>
      <Header whiteBackground />
      <HeaderSection>
        <h1>{event ? 'Edit Event' : 'Create Event'}</h1>
        <Input type="text" name="Name" value={name} onChange={(val) => { setName(val); setUrl(val.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()); }} />
        <div>{`Event URL: https://worldlogicday.com/events/${year}/${escape(url)}`}</div>
        <div ref={(e) => onRef(e, 'description', description, setDescription)}></div>
        <Input name="Additional Website" type="text" value={externalUrl} onChange={setExternalUrl} />
        <Input name="Attendee Limit" type="number" value={maxGuests} onChange={setMaxGuests} />
        <Select name="TimeZone" options={timeZones} value={timeZone} onChange={(val) => setTimeZone(val)} />
        <Select name="Country" options={countries} value={country} onChange={(val) => setCountry(val)} />
        <Input name="Start Time" type="datetime-local" value={startTime} onChange={setStartTime} />
        <Input name="End Time" type="datetime-local" value={endTime} onChange={setEndTime} />
        <Input name="Location Name" type="text" value={location} onChange={setLocation} />
        <Input key={address} name="Address" type="text" value={address} onBlur={(val) => {
          geocoder = geocoder || new window.google.maps.Geocoder();
          geocoder.geocode({ address: val }, function (results, status) {
            if (status === 'OK') {
              const location = results[0].geometry.location;
              setGeo({ type: 'Point', coordinates: [location.lng(), location.lat()] });
              setAddress(results[0].formatted_address);
            }
          });
        }} />
        {geo && geo.coordinates && <div>Lat: {geo.coordinates[1]}, Lng: {geo.coordinates[0]}</div>}
        <div>Optional: Logo image for the event listings (if left blank, country flag will be used)</div>
        <ChangeableImage
          allowDeleting
          onlyAllowImage
          s3Path={s3Path}
          signingUrl={signingUrl}
          style={{
            width: 120,
            height: 120,
            borderRadius: '1rem',
            border: '1px dashed'
          }}
          backgroundImage={image}
          onUploadFinish={setImage}
          onDelete={() => setImage('')}
        />
        <div>Optional: Banner image for the top of the event page</div>
        <ChangeableImage
          allowDeleting
          onlyAllowImage
          s3Path={s3Path}
          signingUrl={signingUrl}
          style={{
            width: 240,
            height: 240,
            borderRadius: '1rem',
            border: '1px dashed'
          }}
          backgroundImage={eventImage}
          onUploadFinish={setEventImage}
          onDelete={() => setEventImage('')}
        />
        <div>Optional: Picture of where the event will take place</div>
        <ChangeableImage
          allowDeleting
          onlyAllowImage
          s3Path={s3Path}
          signingUrl={signingUrl}
          style={{
            width: 240,
            height: 240,
            borderRadius: '1rem',
            border: '1px dashed'
          }}
          backgroundImage={mapImage}
          onUploadFinish={setMapImage}
          onDelete={() => setMapImage('')}
        />
      </HeaderSection>
      {hosts && <Speakers>
        <h2>Hosts</h2>
        <p>Who is hosting the event?</p>
        <Profiles>
          {hosts.map((h, i) => {
            const user = users && users.find(({ _id }) => h._id === _id) || {};
            return (
              <div key={`${JSON.stringify(h)}${i}`}>
                <div>
                  {i > 0 && <i className="fas fa-arrow-up" onClick={() => { hosts.splice(i, 1); hosts.splice(i - 1, 0, h); setHosts([ ...hosts ]); }}></i>}
                  {i < hosts.length - 1 && <i className="fas fa-arrow-down" onClick={() => { hosts.splice(i, 1); hosts.splice(i + 1, 0, h); setHosts([ ...hosts ]); }}></i>}
                </div>
                <DeleteWithConfirm onClick={() => { hosts.splice(i, 1); setHosts([ ...hosts ]); }} />
                <h3>Host #{i + 1}</h3>
                <div>
                  <ChangeableImage
                    allowDeleting
                    onlyAllowImage
                    s3Path={s3Path}
                    signingUrl={signingUrl}
                    style={{
                      width: 180,
                      height: 180,
                      borderRadius: '1rem',
                      border: '1px dashed'
                    }}
                    backgroundImage={h.image || user && user.image && user.image.link}
                    onUploadFinish={(image) => { h.image = image; setHosts([...hosts]); }}
                    onDelete={() => { h.image = ''; setHosts([...hosts]) }}
                  />
                </div>
                <Input type="text" name="Name" value={h.name || user && user.fullname} onChange={(val) => { h.name = val; }} />
                <Input type="text" name="Title" value={h.title} onChange={(val) => { h.title = val; }} />
                <Input type="text" name="LinkedIn" value={h.linkedIn} onChange={(val) => { h.linkedIn = val; }} />
              </div>
            )
          })}
          <Button onClick={() => setHosts([...hosts, {
            image: '',
            name: '',
            title: '',
            linkedIn: ''
          }])}>Add New Host</Button>
        </Profiles>
      </Speakers>}
      {program && <Program>
        <h2>Speakers</h2>
        <p>What presentations and speakers will there be?</p>
        {program.map((p, i) => (
          <div key={`${JSON.stringify(p)}${i}`}>
            <div>
              {i > 0 && <i className="fas fa-arrow-up" onClick={() => { program.splice(i, 1); program.splice(i - 1, 0, p); setProgram([ ...program ]); }}></i>}
              {i < program.length - 1 && <i className="fas fa-arrow-down" onClick={() => { program.splice(i, 1); program.splice(i + 1, 0, p); setProgram([ ...program ]); }}></i>}
            </div>
            <DeleteWithConfirm onClick={() => { program.splice(i, 1); setProgram([ ...program ]) }} />
            <h3>Program #{i + 1}</h3>
            <Input type="text" name="Title" value={p.title} onChange={(val) => { p.title = val; }} />
            <TextArea type="text" name="Description" value={p.description} onChange={(val) => { p.description = val; }} />
            <Input name="Start Time" type="datetime-local" value={p.startTime} onChange={(val) => { p.startTime = val; }} />
            <Input name="End Time" type="datetime-local" value={p.endTime} onChange={(val) => { p.endTime = val; }} />
            <Profiles>
              {p.speakers.map((h, i) => {
                const user = users && users.find(({ _id }) => h._id === _id) || {};
                return (
                  <div key={`${JSON.stringify(h)}${i}`}>
                    <div>
                      {i > 0 && <i className="fas fa-arrow-up" onClick={() => { p.speakers.splice(i, 1); p.speakers.splice(i - 1, 0, h); setProgram([ ...program ]); }}></i>}
                      {i < p.speakers.length - 1 && <i className="fas fa-arrow-down" onClick={() => { p.speakers.splice(i, 1); p.speakers.splice(i + 1, 0, h); setProgram([ ...program ]); }}></i>}
                    </div>
                    <DeleteWithConfirm onClick={() => { p.speakers.splice(i, 1); setProgram([ ...program ]); }} />
                    <h3>Speaker #{i + 1}</h3>
                    <div>
                      <ChangeableImage
                        allowDeleting
                        onlyAllowImage
                        s3Path={s3Path}
                        signingUrl={signingUrl}
                        style={{
                          width: 180,
                          height: 180,
                          borderRadius: '1rem',
                          border: '1px dashed'
                        }}
                        backgroundImage={h.image || user && user.image && user.image.link}
                        onUploadFinish={(image) => { h.image = image; setProgram([ ...program ]); }}
                        onDelete={() => { h.image = ''; setProgram([ ...program ]); }}
                      />
                    </div>
                    <Input type="text" name="Name" value={h.name || user && user.fullname} onChange={(val) => { h.name = val; }} />
                    <Input type="text" name="Title" value={h.title} onChange={(val) => { h.title = val; }} />
                    <Input type="text" name="LinkedIn" value={h.linkedIn} onChange={(val) => { h.linkedIn = val; }} />
                  </div>
                );
              })}
              <Button onClick={() =>{ p.speakers.push({
                image: '',
                name: '',
                title: '',
                linkedIn: ''
              }); setProgram([ ...program ]); } }>Add New Speaker</Button>
            </Profiles>
          </div>
        ))}
        <Button style={{ marginTop: '3rem' }} onClick={() => setProgram([...program, {
          title: '',
          description: '',
          speakers: [],
        }])}>Add New Program</Button>
      </Program>}
      <CancelSave>
        <Button onClick={() => window.location.reload()}>Reset</Button>
        <Button onClick={() => {
          const newEvent = {
            owner: event && event.owner || account._id,
            name,
            description,
            startTime,
            endTime,
            address,
            location,
            timeZone,
            hosts,
            program,
            maxGuests,
            externalGuests,
            year,
            url,
            externalUrl,
            country,
            geo,
            image,
            mapImage,
            eventImage,
          };
          isCreating ? setSaving(newEvent) : setUpdating(newEvent);
        }}>{isCreating ? 'Create' : 'Save'}</Button>
      </CancelSave>
      <Footer />
    </Main>
  )
};
export default CreateEditEvent;
