import React from "react";
import styled from "styled-components";
import Map from "../../components/Map";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import EventsWidget from "../../components/Events";
import useApiQuery from "../../submodules/logictry_common/src/hooks/server/useApiQuery";
import { EVENTS_COLLECTION } from "../../collections";
import years from "../../utils/years";
import useAppQuery from "../../submodules/logictry_common/src/hooks/server/useAppQuery";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";

const Main = styled.main`
  > section {
    margin: 8rem auto;
    max-width: 960px;
  }
  #map {
    position: absolute;
    inset: 0;
  }
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const Events = () => {
  const { account } = useConfig();
  const queries: any = [];
  for (let i = 0; i < years.length; i += 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { query } = useApiQuery(EVENTS_COLLECTION, [{ key: 'sort', value: '+startTime' }, { key: 'limit', value: 200 }, { key: 'year', value: parseInt(years[i]) }], []);
    queries.push(query);
    const ids = query && query.map((event: any) => event.owner).filter((v: string) => v).join(',');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { query: users } = useAppQuery('users', account && account._id === '5cf13c4899397a33fd191007' && ids && [{ key: 'ids', value: ids }], []);
    if (account && account._id === '5cf13c4899397a33fd191007') console.log('i', i, users && users.map(({ email }: any) => email).join(','))
  }
  return (
    <Main>
      <Header>
        <h1>Events</h1>
      </Header>
      {/* <JoinMovement>
        <h2>Join the Logic Movement</h2>
        <div>
          <div>
            <img src="https://www.idm314.org/assets/img/idm-ph-03.jpg"></img>
            <h3>Organize an event</h3>
            <p>We'll help people in your town find out about your event.</p>
          </div>
          <div>
            <img src="https://www.idm314.org/assets/img/idm-ph-03.jpg"></img>
            <h3>View events</h3>
            <p>We'll help people in your town find out about your event.</p>
          </div>
          <div>
            <img src="https://www.idm314.org/assets/img/idm-ph-03.jpg"></img>
            <h3>Publish your event</h3>
            <p>We'll help people in your town find out about your event.</p>
          </div>
        </div>
      </JoinMovement> */}
      <section>
        <h2>{years[0]} Events</h2>
        <div style={{ height: 500, position: 'relative', width: '100%', margin: 'auto'  }}>
          <Map events={queries[0] || []} />
        </div>
      </section>
      {years.map((year, i) => (
        <EventsWidget title={`${year} Events`} events={queries[i] || []} />
      ))}
      <Footer />
    </Main>
  )
};
export default Events;
