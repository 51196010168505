import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ImageFloatRight from "../ImageFloatRight";
import { device } from "../../submodules/logictry_common/src/utils/device";

const Logictry = styled.section`
  img {
    width: 240px;
  }
  > div:nth-child(2) {
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    gap: 1rem;
    * {
      max-width: 100%;
    }
    @media ${device.mobileL} {
      float: unset;
      margin-left: unset;
      margin-bottom: unset;
      gap: 0.5rem;
      justify-content: space-between;
    }
  }
`;

const LogictryProclamation = () => (
  <Logictry>
    <h2>Logictry Proclamation</h2>
    <div>
      <div>
        <img style={{ objectFit: 'cover', height: 240, width: 160 }} src="https://logictryuserprofiles.s3.amazonaws.com/5cf13c4899397a33fd191007/cc158a6035b1830532944a1ed86dc614/chris_fronda_nexus.jpeg"></img>
        <div><b>Chris Fronda</b></div>
        <div>Chief Logic Officer</div>
      </div>
      <div>
        <img style={{ objectFit: 'cover', height: 240, width: 160 }} src="https://logictryuserprofiles.s3.amazonaws.com/61b9066d992922729af8abe5/5ea25c7a63edbb050c91d4ec6be2778a/Chelsea%20T%20%28WLF%29.jpeg"></img>
        <div><b>Chelsea Toler</b></div>
        <div>Chief Impact Officer</div>
      </div>
    </div>
    <p>We define Logic as the science of reasoning and thinking clearly. It helps us identify what makes an argument or idea valid, consistent, and sound, so we can make better decisions and avoid mistakes in our thinking.</p>
    <p>In accordance with United Nations Sustainable Development Goals 16 & 17 and UNESCO's initiative, we believe that <b>collaboration</b> and <b>logic</b> prove imperative for helping create a better, more peaceful world.</p>
    <p>With the new era of AI, we are working tirelessly to humanize AI and to leverage our logic layer to help humans learn how to think rather than what to think. Alongside our partners, we can help close the decision gap for the world while democratizing access to some of the world’s most curated knowledge sources and leaders on a global scale. Logictry is proud to join other champions of Logic and to help inspire a <b>Logic Movement</b> focused on Ethical AI, advancement of UN SDGs 16 & 17, supporting the next generation of future leaders,  and World Peace with better access to education for all.</p>
  </Logictry>
);
export default LogictryProclamation;