/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Map from "../../components/Map";
import Navbar from '../../components/Navbar/index';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CountDown from "../../components/Countdown";

import { NavLink, useLocation } from "react-router-dom";
import { AnyARecord } from "dns";
import { device } from "../../submodules/logictry_common/src/utils/device";
import useAppGet from "../../submodules/logictry_common/src/hooks/server/useAppGet";
import useAppQuery from "../../submodules/logictry_common/src/hooks/server/useAppQuery";
import useAppPost from "../../submodules/logictry_common/src/hooks/server/useAppPost";
import useApiQuery from "../../submodules/logictry_common/src/hooks/server/useApiQuery";
import { CONTACTS_COLLECTION, EVENTS_COLLECTION, GUESTS_COLLECTION, PAYMENTS_COLLECTION } from "../../collections";
import Button from "../../styledhtml/Button";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import years from "../../utils/years";
const moment = require('moment-timezone');

const Main = styled.main`
  #map {
    position: absolute;
    inset: 0;
  }
  h2 {
    text-align: center;
    margin-bottom: 4rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    margin: 0;
  }
  header {
    p {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
`;
const About = styled.section`
  img {
    width: 100%;
  }
`;
const SponsorsAndPartners = styled.section`
  margin: 4rem auto 8rem;
  max-width: 904px;
  padding: 1rem;
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    > div {
      text-align: center;
      width: 180px;
      > img {
        object-fit: contain;
        width: 100%;
        max-height: 60px;
      }
      h3 {
        margin-top: 1rem;
      }
    }
  }
`;
const Program = styled.section`
  margin: 4rem auto 8rem;
  max-width: 904px;
  padding: 1rem;
  h2 {
    margin-top: 2rem;
  }
  > div {
    margin: 6rem 0;
  }
`;
const Speakers = styled.section`
  margin: 4rem auto 8rem;
  max-width: 904px;
  padding: 1rem;
  > div:last-child {
    margin-top: 3rem;
    justify-content: center;
  }
`;
const Guests = styled.section`
  margin: 4rem auto 8rem;
  max-width: 1080px;
  padding: 1rem;
  > div:last-child {
    margin-top: 3rem;
    justify-content: center;
  }
`;
const Profiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  > div {
    text-align: center;
    width: 280px;
    height: 280px;
    position: relative;
    overflow: hidden;
    > img {
      object-fit: cover;
      width: 280px;
      height: 280px;
    }
    > div:last-child {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 140px;
      transition: 0.3s all;
      transform: translateY(40px);
      background: rgba(0,0,0,0.76);
      color: white;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        font-weight: bold;
      }
      div {
        font-size: 0.8rem;
      }
      > div:nth-child(2) {
        flex: 1;
      }
      i {
        margin: 0.5rem;
      }
    }
  }
  > div:hover {
    > div {
      transform: translateY(0px);
    }
  }
`
const GuestProfiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  white-space: pre-wrap;
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    > div:last-child {
      display: flex;
      align-items: center;
      > div:last-child {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
`;
const Location = styled.section`
  margin: 4rem auto 8rem;
  display: flex;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  iframe, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > div {
    height: 400px;
    width: 50%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    position: relative;
    > div {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      background-color: rgba(0,0,0,0.5);
      > div {
        font-size: 1rem;
      }
    }
  }
`;
const Register = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  > * {
    text-decoration: none;
    > div {
      font-size: 1.2rem;
      margin: 2rem 0 0;
      padding: 0.75rem 2rem;
      text-align: center;
      border-radius: 2rem;
      display: block;
      border: 1px solid white;
      color: white;
      cursor:pointer;
      background-color: rgba(0,0,0,0.4);
    }
    > div:hover {
      background-color: white;
      color: black;
    } 
  }
`;
const Tickets = styled.section`
  margin: 4rem auto 8rem;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 680px) {
      flex-direction: column;
    }
    margin: 2rem;
    gap: 3rem;
    > div {
      padding: 1rem 2rem;
      box-shadow: 0 10px 25px 0 rgba(0,0,0, 0.1);
      border-radius: 0.5rem;
      max-width: 100%;
      width: 280px;
      > div:first-child {
        text-align: center;
        padding: 2rem 1rem;
        border-bottom: 1px solid gray;
      }
      > div:nth-child(2) {
        padding: 1rem;
        border-bottom: 1px solid gray;
        ul {
          padding-left: 1rem;
        }
        li {
          margin: 0.5rem 0;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
`;

const Programs = (props: { title: string, description: string, startTime: string, endTime: string, speakers: any }) => (
  <div>
    <h3>{props.title}</h3>
    {props.startTime && props.endTime && <span style={{ fontSize: '0.8rem' }}>
      <i>{(new Date(props.startTime)).toLocaleString('default', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}</i> - <i>{(new Date(props.startTime)).toLocaleString('default', {
        hour: 'numeric',
        minute: 'numeric'
      })} - {(new Date(props.endTime)).toLocaleString('default', {
        hour: 'numeric',
        minute: 'numeric'
      })}</i>
    </span>}
    <p>{props.description}</p>
    {props.speakers && props.speakers.length > 0 && <>
      <br></br>
      <ProfileSection profiles={props.speakers} />
    </>}
  </div>
);

const ProfileSection = (props: { profiles: { image: any, name: string, title: string, linkedIn?: string, youtube?: string }[] }) => (
  <Profiles>
    {props.profiles.map(({ image, name, title, linkedIn }, i) => (
      <div key={name}>
        <img alt={name} src={image}></img>
        <div>
          <h4>{name}</h4>
          <div>{title}</div>
          <div>
            {linkedIn && <a href={linkedIn}>
              Learn more
            </a>}
          </div>
        </div>
      </div>
    ))}
  </Profiles>
);
const GuestProfileSection = (props: { profiles: { image: any, name: string, email?: string, title: string, payment?: any, tags: [] }[] }) => (
  <GuestProfiles>
    {[`Name\temail`, ...props.profiles.map(({ name, email }) => (
      `${name || 'guest'}\t${email}`
    ))].join('\r\n')}
  </GuestProfiles>
);
const EventAnalytics = () => {
  const { account } = useConfig();
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const { query: events } = useApiQuery(EVENTS_COLLECTION, paths[3] ? [{ key: 'year', value: paths[2] }, { key: 'url', value: paths[3] }] : [{ key: '_id', value: paths[2] }], []);
  const event = events && events[0] || events;
  const { query: guests } = useApiQuery(GUESTS_COLLECTION, event && event._id && [{ key: 'event', value: event._id }, { key: 'limit', value: 2000 }], []);

  let contacts: any = [];
  let payments: any = [];
  let users: any = [];
  for (let i = 0; i < 20; i++) {
    let ids = guests && guests.length > 0 && guests.slice(i * 100, i * 100 + 100).map(({ _id }: any) => _id).join(',');
    const { query: newPayments } = useApiQuery(PAYMENTS_COLLECTION, ids && [{ key: 'ids', value: ids }, { key: 'limit', value: 2000 }], []);
    const { query: newContacts } = useApiQuery(CONTACTS_COLLECTION, ids && [{ key: 'ids', value: ids }, { key: 'limit', value: 2000 }], []);
    ids = event && guests && event.hosts && [...event.hosts.slice(i * 100, i * 100 + 100).map(({ _id }: any) => _id), ...guests.slice(i * 100, i * 100 + 100).map(({ guest }: any) => guest)].filter((v: string) => v).join(',');
    const { query: newUsers } = useAppQuery('users', ids && [{ key: 'ids', value: ids }], []);
    contacts = [...contacts, ...(newContacts || [])];
    payments = [...payments, ...(newPayments || [])];
    users = [...users, ...(newUsers || [])];
  }

  if (!account || !account.loggedIn) {
    window.location.href = `${window.location.origin}/login`;
    return null;
  }
  if (event && ![event.owner, ...(event.admins || [])].includes(account._id)) {
    window.location.href = window.location.href.replace('/analytics', '');
    return null;
  } else if (!event && !account._id) {
    window.location.href = `${window.location.origin}/login`;
    return null;
  }
  if (!event || !guests || !contacts) return null;
  return (
    <Main>
      <Header whiteBackground />
      {event.year === parseInt(years[0]) && <Guests>
        <h2>Attendees ({event.maxGuests ? `${guests.length} / ${event.maxGuests}` : `${guests.length}`})</h2>
        <GuestProfileSection profiles={guests.map(({ _id, guest, tags }: any) => {
          const user = users && users.find(({ _id }: any) => guest === _id) || {};
          const contact = contacts && contacts.find((g: any) => g._id === _id) || {};
          const payment = payments && payments.find((p: any) => p._id === _id);
          return {
            image: user && user.image && user.image.link,
            name: user && user.fullname,
            email: contact && contact.email || user && user.email,
            payment,
            tags
          };
        })} />
      </Guests>}
      <Footer />
    </Main>
  )
};
export default EventAnalytics;
