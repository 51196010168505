import React from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CipshProclamation from "../../components/CipshProclamation";
import UnescoProclamation from "../../components/UnescoProclamation";
import ImageFloatRight from "../../components/ImageFloatRight";
import { device } from "../../submodules/logictry_common/src/utils/device";
import JeanYvesBeziauProclamation from "../../components/JeanYvesBeziauProclamation";
import LogictryProclamation from "../../components/LogictryProclamation";

const Main = styled.main`
  > section {
    margin: 8rem auto;
    max-width: 960px;
    padding: 0 1rem;
  }
  #map {
    position: absolute;
    inset: 0;
  }
  
  h2 {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    @media ${device.mobileL} {
      text-align: center;
    }
  }
`;
const Significance = styled.section`
  img {
    width: 240px;
  }
`;


const About = () => {
  return (
    <Main>
      <Header>
        <h1>About</h1>
      </Header>
      <LogictryProclamation />
      <JeanYvesBeziauProclamation />
      <Significance>
        <h2>January 14th Significance</h2>
        <ImageFloatRight>
          <img src="http://www.logica-universalis.org/im-wld/tarski-godel-january-14.jpg"></img>
          <div><b>Alfred Tarski / Kurt Gödel</b></div>
        </ImageFloatRight>
        <p>The logo of the Logic Day is connected with the one of the <a href="https://en.wikipedia.org/wiki/Esperanto_symbols" target="_blank">Esperanto</a>.</p>
        <p>The question of a universal language was popular among logicians at the turn of the 20th century. <a href="https://en.wikipedia.org/wiki/Giuseppe_Peano" target="_blank">Giuseppe Peano</a> created one, <a href="https://en.wikipedia.org/wiki/Latino_sine_flexione" target="_blank">Latino sine flexione</a>, <a href="https://en.wikipedia.org/wiki/Louis_Couturat" target="_blank">Louis Couturat</a>  another one,  <a href="https://en.wikipedia.org/wiki/Ido_language" target="_blank">Ido</a>, and <a href="https://en.wikipedia.org/wiki/Rudolf_Carnap" target="_blank">Rudolf Carnap</a> was fluently speaking Esperanto as explained in his  <a href="http://fitelson.org/confirmation/carnap_schilpp_volume.pdf" target="_blank">intellectual autobiography</a>. The idea of <a href="https://en.wikipedia.org/wiki/L._L._Zamenhof" target="_blank">Ludwik Lejzer Zamenhof</a>, creator of Esperanto, was to promote peace and understanding among human beings by the development of an artificial  universal language. This did not work. English is itself  being used as a universal language and it makes sense if we consider some of its typical features,  already pointed out at the end of the 19th century by <a href="https://en.wikipedia.org/wiki/Michel_Br%C3%A9al" target="_blank">Michel Bréal</a>,  who established <a href="https://www.tandfonline.com/doi/abs/10.1080/00437956.1948.11659331" target="_blank">"semantics"</a>, and who was the teacher of <a href="https://en.wikipedia.org/wiki/Ferdinand_de_Saussure" target="_blank">Ferdinand de Saussure</a>.</p>
        <p>But more important than a universal language is human reason which is itself by nature universal and that's  an excellent reason to promote logic. Nowadays few people know what logic is. And there are various reasons for that, in particular because it is spread in various departments and most of the time there are no interactions within a given university between people working in logic in a computer science department, a math department, a philosophy department, a linguistics department. And for the layman logic merely reduces to the adjective "logical", he has no idea that logic is a science.</p>
        <p>Logic is not  one field among others. If we take logic seriously, as the study of reasoning, and if  we consider that reasoning is the distinctive feature of human beings, considered as rational animals since the antiquity ("logical" animals originally in Greek), then logic is something really special. The World Logic Day is the celebration of humanity.</p>
      </Significance>
      <UnescoProclamation />
      <CipshProclamation />
      <Footer />
    </Main>
  )
};
export default About;
