import React from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CipshProclamation from "../../components/CipshProclamation";
import UnescoProclamation from "../../components/UnescoProclamation";
import YouTube from "../../components/YouTube";
import ImageFloatRight from "../../components/ImageFloatRight";
import { device } from "../../submodules/logictry_common/src/utils/device";

const Main = styled.main`
  > section {
    margin: 4rem auto;
    max-width: 968px;
    padding: 0 1rem;
  }
  #map {
    position: absolute;
    inset: 0;
  }
  
  h2 {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    @media ${device.mobileL} {
      text-align: center;
    }
  }
`;

const Logictry = styled.section`
  img {
    width: 240px;
  }
  > div:nth-child(2) {
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    gap: 1rem;
    * {
      max-width: 100%;
    }
    @media ${device.mobileL} {
      float: unset;
      margin-left: unset;
      margin-bottom: unset;
    }
  }
`;
const Significance = styled.section`
  img {
    width: 240px;
  }
`;
const Creators = styled.section`
  img {
    width: 240px;
  }
  > div:last-child {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    > div {
      width: calc(50% - 1rem);
      @media ${device.mobileL} {
        width: 100%;
      }
    }
  }
`;

const Videos = () => {
  return (
    <Main>
      <Header>
        <h1>Videos</h1>
      </Header>
      <Logictry>
        <iframe style={{ border: 0, width: '100%', height: '500px', margin: '4rem 0' }} src="https://localhost/collections/6765c78ad245af2839878ae8?allowfullscreen=false&autoresize=false"></iframe>
        <iframe style={{ border: 0, width: '100%', height: '500px', margin: '4rem 0' }} src="https://logictry.com/collections/6765b722d245af2839877693?allowfullscreen=false&autoresize=false"></iframe>
        <iframe style={{ border: 0, width: '100%', height: '500px', margin: '4rem 0' }} src="https://logictry.com/collections/6765b30bd245af2839877268?allowfullscreen=false&autoresize=false"></iframe>
      </Logictry>
      <Footer />
    </Main>
  )
};
export default Videos;
