import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ImageFloatRight from "../ImageFloatRight";
import YouTube from "../../components/YouTube";
import { device } from "../../submodules/logictry_common/src/utils/device";

const Creators = styled.section`
  img {
    width: 240px;
  }
  > div:last-child {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    > div {
      width: calc(50% - 1rem);
      @media ${device.mobileL} {
        width: 100%;
      }
    }
  }
`;

const JeanYvesBeziauProclamation = () => (
  <Creators>
    <h2>Founding of World Logic Day</h2>
    <ImageFloatRight>
      <img src="http://logica-universalis.org/im-wld-rio/jyb.jpg"></img>
      <div><b>Jean-Yves Beziau</b></div>
      <div>President of LUA & Professor at the University of Brazil</div>
    </ImageFloatRight>
    <p>Why creating a World Logic Day? Human beings are classically considered as "Logical Animals" (Latinized as "Rational Animals"). Logic, as reasoning, is a central feature of human beings. It is not very difficult to see what can be promoted on January 14, considered as the World Logic Day: rationality, understanding and intelligence.</p>
    <p>The beginning of the year is symbolically good: it is fine to start the new year with a logical perspective. According to the Julian calendar (calendar promoted by Julius Cæsar, in use from 45 B.C. to 1582 A.D., still in use in some locations), January 14 is indeed New Year’s Day. To unify all logicians in one day at the beginning of every year is a good way to boost logic.</p>
    <p>The basic idea of a World Logic Day and other related projects such as the Logic Prizes Contest, an Encyclopædia of Logic, Word Logic Conferences, is to put logic in its right place, i.e the first place.</p>
    <p>Jean-Yves Beziau (JYB) is a Swiss Logician, Philosopher and Mathematician, PhD in mathematics and PhD in Philosophy. He has been living and working in different places: France, Brazil, Poland, Corsica, California (UCLA, Stanford, UCSD), Switzerland. He is currently Professor at the University of Brazil in Rio de Janeiro, former Coordinator of Graduate Studies in Philosophy and former President of the Brazilian Academy of Philosophy.</p>
    <p>JYB is the creator of the <a href="https://www.youtube.com/watch?v=UJJDL9MKYOk" target="_blank" rel="nofollow"><em>World Logic Day</em></a>, yearly celebrated on January 14 (<a href="https://en.unesco.org/commemorations/international-days" target="_blank" rel="nofollow"><em>UNESCO international days</em></a>), the <a href="https://sites.google.com/view/unilog-2022/logic-prizes" target="_blank" rel="nofollow"><em>World Logic Prizes Contest</em></a>, the founder and Editor-in-Chief of the book series <a href="http://www.collegepublications.co.uk/lphd/" target="_blank" rel="nofollow"><em>Logic PhDs</em></a> and the logic area editor of the <a href="https://iep.utm.edu/" target="_blank" rel="nofollow"><em>Internet Encyclopedia of Philosophy</em></a>. </p>
    <p>JYB is the originator of Universal Logic as a general theory of logical structures, and the founder and Editor-in-Chief of the journal <a href="https://www.springer.com/journal/11787" target="_blank" rel="nofollow"><em>Logica Universalis</em></a> and book series <a href="http://logica-universalis.org/sul" target="_blank" rel="nofollow"><em>Studies in Universal Logic</em></a>, both published by Birkhäuser/Springer, Basel. He has recently launched the <a href="https://www.springer.com/journal/11787/updates/18988758" target="_blank" rel="nofollow"><em>Logica Universalis Webinar</em></a> and is the current president of <a href="http://logica-universalis.org/LUAD" target="_blank" rel="nofollow"><em>LUA</em></a> and
    is organizing a series of events on universal logic around the world: <a href="https://www.uni-log.org/" target="_blank" rel="nofollow"><em>UNILOG - World Congress and School on Universal Logic</em></a> (Montreux 2005, Xi'an 2007, Lisbon 2010, Rio de Janeiro, 2013, Istanbul 2015, Vichy 2018, Crete 2022).</p>
    <p>JYB has made important contributions in paraconsistent logic (former student of Newton da Costa, JYB is editor of the <a href="http://www.paraconsistency.org/" target="_blank" rel="nofollow"><em>Paraconsistent Newsletter</em></a> and organizer of the 3rd, 5th and 6th World Congress on Paraconsistency). JYB has shown in particular that the modal logic S5 is a paraconsistent logic and that the nameless corner of the square of opposition is a paraconsistent negation.
    JYB has revived the study of the square of opposition organizing interdisciplinary world events on this topic <a href="https://www.square-of-opposition.org/" target="_blank" rel="nofollow"><em>SQUARE</em></a> (Montreux 2007, Corsica 2010, Beirut 2012, Vatican 2014, Easter Island 2016, Crete 2018, Leuven 2022) and organizing the publication of special issues of journals and books on the subject.</p>
    <p>JYB is working at promoting logic in South America. He has launched in 2015 together with Marcelo Coniglio the <a href="http://www.sa-logic.org/" target="_blank" rel="nofollow"><em>South American Journal of Logic</em></a></p>
    <p>JYB has also been working on imagination, symbolism and semiotics. He has systematically used images to write philosophical papers in a series of <a href="http://www.jyb-logic.org/imaginary" target="_blank" rel="nofollow"><em>IMAGINARY PAPERS</em></a> and organizing a big international event on imagination in Rio de Janeiro in 2018, <a href="http://www.imagination2018.fr/" target="_blank" rel="nofollow"><em>L'IMAGINATION</em></a>. As the President of the Brazilian Academy of Philosophy, JYB also has organized in the marvelous city the event <a href="https://sites.google.com/view/creativity2019/" target="_blank" rel="nofollow"><em>CREATIVITY'2019</em></a>. In Geneva, JYB organized the workshop <a href="http://www.jyb-logic.org/a" target="_blank" rel="nofollow"><em>ARBITRARINESS OF THE SIGN</em></a> part of Saussure CLG Centenary.</p>
    <p>With <a href="https://philpeople.org/profiles/ricardo-silvestre" target="_blank" rel="nofollow"><em>Ricardo Silvestre</em></a>, JYB has launched the series of events <a href="https://www.logicandreligion.com/3rd-world-congress" target="_blank" rel="nofollow"><em>WoCoLoR - World Congress on Logic and Religion</em></a> and created the association <a href="https://www.logicandreligion.com/lara" target="_blank" rel="nofollow"><em>LARA</em></a> which is organizing a <a href="https://www.logicandreligion.com/webinars" target="_blank" rel="nofollow"><em>webinar</em></a> on the topic.</p>
    <p>JYB is a member of the editorial board of various journals including
    <a href="https://www.mdpi.com/journal/philosophies" target="_blank" rel="nofollow"><em>Philosophies</em></a> (MPDI), <a href="https://www.mdpi.com/journal/logics" target="_blank" rel="nofollow"><em>Logics</em></a> (MPDI), <a href="https://www.frontiersin.org/journals/psychology" target="_blank" rel="nofollow"><em>Frontiers in Psychology</em></a> (Frontiers Media) and the <a href="https://academic.oup.com/logcom" target="_blank" rel="nofollow"><em>Journal of Logic and Computation</em></a> (Oxford University Press).</p>
    <p><a href="https://link.springer.com/article/10.1007/s11787-019-00221-5">1st World Logic Day: 14 January 2019</a></p>
    <div>
      <div>
        <YouTube url={'https://www.youtube.com/embed/UJJDL9MKYOk?si=AxsDfumWLZM2FV12'} />
      </div>
      <div>
        <YouTube url={'https://www.youtube.com/embed/OrdPQP2Og7k?si=mW5EJZ7q5dZsO1lH'} />
      </div>
      <div>
        <YouTube url={'https://www.youtube.com/embed/2ujhnjPcSZg?si=Ch071MGIMD1qctY-'} />
      </div>
      <div>
        <YouTube url={'https://www.youtube.com/embed/RayDgk-KyiQ?si=VmL-Erz-mhM1NVIY'} />
      </div>
    </div>
  </Creators>
);
export default JeanYvesBeziauProclamation;