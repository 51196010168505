import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { device } from "../../submodules/logictry_common/src/utils/device";
import { isEmbedded } from "../../submodules/logictry_common/src/utils/getEnvironment";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";

const Wrapper = styled.nav`
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  margin: auto;
  z-index: 1;
  transition: 0.3s all;
  img {
    height: 2.5rem;
  }
  > div:nth-child(2) {
    flex: 1;
    justify-content: center;
    @media ${device.mobileL} {
      > a:first-child {
        display: none;
      }
    }
  }
  > div:last-child {
    justify-content: center;
    width: 72px;
    height: 72px;
    img {
      border-radius: 50%;
    }
  }
  > div {
    display: flex;
    align-items: center;
    > * {
      padding: 1rem;
      @media ${device.mobileL} {
        padding: 0.75rem;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    a {
      font-size: 1rem;
      text-decoration: none;
    }
    > div {
      position: relative;
      display: inline-block;
      > div:last-child {
        display: none;
        position: absolute;
        left: -70px;
        top: 55px;
        background-color: white;
        min-width: 220px;
        border-radius: 0.5rem;
        z-index: 1;
        text-align: center;
        box-shadow: rgba(136, 152, 170, 0.1) 0px 0px 0px 1px, rgba(49, 49, 93, 0.1) 0px 15px 35px, rgba(0, 0, 0, 0.08) 0px 5px 15px;
        > a {
          color: black;
          padding: 1rem;
          text-decoration: none;
          display: block;
        }
        > a:hover {
          background-color: rgba(0,0,0,0.05);
        }
      }
    }
    > div:hover div {
      display: block;
    }
  }
  > div:first-child {
    > * {
      padding: 1rem;
    }
    width: 72px;
    height: 72px;
    justify-content: flex-start;
    @media ${device.mobileL} {
      margin-right: 0;
    }
  }
`;

// let navRef;
// const setColor = () => {
//   if (!navRef || isEmbedded) return;
//   if (window.scrollY > 60) {
//     navRef.style.background = 'white';
//     navRef.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 0.0625rem 0.175rem';
//     navRef.style.color = 'black';
//   } else {
//     navRef.style.background = '';
//     navRef.style.boxShadow = '';
//     navRef.style.color = 'white';
//   }
// }
// document.addEventListener('scroll', setColor);
const Navbar = (props) => {
  const { account } = useConfig();
  const initials = account && (account.fullname && account.fullname.split(' ').map((_string) => _string.charAt(0)).slice(0, 3) || account.username && account.username.slice(0, 3));
  return (
    <Wrapper style={{ position: isEmbedded ? 'absolute': 'absolute', color: props.whiteBackground ? 'black' : 'white' }}>
      <div>
        <NavLink to={'/'}>
          <img style={{ filter: props.whiteBackground ? 'invert(1)' : null }} src="https://logictrystatic.s3.amazonaws.com/images/icon_sharp_white.svg"></img>
        </NavLink>
      </div>
      <div>
        <NavLink to={'/'}>
          Home
        </NavLink>
        <NavLink to={'/about'}>
          About
        </NavLink>
        <NavLink to={'/events'}>
          Events
        </NavLink>
      </div>
      {account && <div style={account.loggedIn && {} || {
        height: '36px',
        background: props.whiteBackground ? 'black' : 'white',
        color: props.whiteBackground ? 'white' : 'black',
        borderRadius: '1rem',
        margin: '1rem'
      }}>
        {account.loggedIn && (
          account.image && account.image.link && <NavLink to={'/account'}><img alt="profile" src={account.image && account.image.link}></img></NavLink>
          || <NavLink to={'/account'}><div style={{ border: `1px dashed ${props.whiteBackground ? 'black' : 'white'}`, padding: '0.75rem', borderRadius: '50%' }}>{initials}</div></NavLink>)
         || <NavLink to={'/login'}>Login</NavLink>}
      </div>}
    </Wrapper>
  )
}
export default Navbar;