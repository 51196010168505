/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Map from "../../components/Map";
import Navbar from '../../components/Navbar/index';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CountDown from "../../components/Countdown";

import { NavLink, useLocation } from "react-router-dom";
import { device } from "../../submodules/logictry_common/src/utils/device";
import useAppGet from "../../submodules/logictry_common/src/hooks/server/useAppGet";
import useAppQuery from "../../submodules/logictry_common/src/hooks/server/useAppQuery";
import useAppPost from "../../submodules/logictry_common/src/hooks/server/useAppPost";
import useApiQuery from "../../submodules/logictry_common/src/hooks/server/useApiQuery";
import { CONTACTS_COLLECTION, EVENTS_COLLECTION, GUESTS_COLLECTION, PAYMENTS_COLLECTION } from "../../collections";
import Button from "../../styledhtml/Button";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import RegisterForm from "../../submodules/logictry_common/src/components/RegisterForm";
import years from "../../utils/years";
const moment = require('moment-timezone');

const Main = styled.main`
  #map {
    position: absolute;
    inset: 0;
  }
  h2 {
    text-align: center;
    margin-bottom: 4rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    margin: 0;
  }
  header {
    p {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
`;
const About = styled.section`
  img {
    width: 100%;
  }
`;
const SponsorsAndPartners = styled.section`
  margin: 4rem auto 8rem;
  max-width: 904px;
  padding: 1rem;
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    > div {
      text-align: center;
      width: 180px;
      > img {
        object-fit: contain;
        width: 100%;
        max-height: 60px;
      }
      h3 {
        margin-top: 1rem;
      }
    }
  }
`;
const Program = styled.section`
  margin: 4rem auto 8rem;
  max-width: 904px;
  padding: 1rem;
  h2 {
    margin-top: 2rem;
  }
  > div {
    margin: 6rem 0;
  }
`;
const Speakers = styled.section`
  margin: 4rem auto 8rem;
  max-width: 904px;
  padding: 1rem;
  > div:last-child {
    margin-top: 3rem;
    justify-content: center;
  }
`;
const Guests = styled.section`
  margin: 4rem auto 8rem;
  max-width: 1080px;
  padding: 1rem;
  > div:last-child {
    margin-top: 3rem;
    justify-content: center;
  }
`;
const Profiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  @media only screen and (max-width: 608px) {
    flex-direction: column;
    align-items: center;
  }
  > div {
    text-align: center;
    width: 280px;
    height: 280px;
    position: relative;
    overflow: hidden;
    > img {
      object-fit: cover;
      width: 280px;
      height: 280px;
    }
    > div:last-child {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 140px;
      transition: 0.3s all;
      transform: translateY(40px);
      background: rgba(0,0,0,0.76);
      color: white;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        font-weight: bold;
      }
      div {
        font-size: 0.8rem;
      }
      > div:nth-child(2) {
        flex: 1;
      }
      i {
        margin: 0.5rem;
      }
    }
  }
  > div:hover {
    > div {
      transform: translateY(0px);
    }
  }
`
const Location = styled.section`
  margin: 4rem auto 8rem;
  display: flex;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  iframe, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > div {
    height: 400px;
    width: 50%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    position: relative;
    > div {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      background-color: rgba(0,0,0,0.5);
      > div {
        font-size: 1rem;
      }
    }
  }
`;
const Register = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  > * {
    text-decoration: none;
    > div {
      font-size: 1.2rem;
      margin: 2rem 0 0;
      padding: 0.75rem 2rem;
      text-align: center;
      border-radius: 2rem;
      display: block;
      border: 1px solid white;
      color: white;
      cursor:pointer;
      background-color: rgba(0,0,0,0.4);
    }
    > div:hover {
      background-color: white;
      color: black;
    } 
  }
`;
const Tickets = styled.section`
  margin: 4rem auto 8rem;
  > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 680px) {
      flex-direction: column;
      align-items: center;
    }
    margin: 2rem;
    gap: 3rem;
    > div {
      padding: 1rem 2rem;
      box-shadow: 0 10px 25px 0 rgba(0,0,0, 0.1);
      border-radius: 0.5rem;
      max-width: 100%;
      width: 280px;
      display: flex;
      flex-direction: column;
      > div:first-child {
        text-align: center;
        padding: 2rem 1rem;
        border-bottom: 1px solid gray;
        flex: 1;
      }
      > div:nth-child(2) {
        padding: 1rem;
        ul {
          padding-left: 1rem;
        }
        li {
          margin: 0.5rem 0;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
`;

const Programs = (props) => (
  <div>
    <h3>{props.title}</h3>
    {props.startTime && props.endTime && <span style={{ fontSize: '0.8rem' }}>
      <i>{(new Date(props.startTime)).toLocaleString('default', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}</i> - <i>{(new Date(props.startTime)).toLocaleString('default', {
        hour: 'numeric',
        minute: 'numeric'
      })} - {(new Date(props.endTime)).toLocaleString('default', {
        hour: 'numeric',
        minute: 'numeric'
      })}</i>
    </span>}
    <p>{props.description}</p>
    {props.speakers && props.speakers.length > 0 && <>
      <br></br>
      <ProfileSection profiles={props.speakers} />
    </>}
  </div>
);

const ProfileSection = (props) => (
  <Profiles>
    {props.profiles.map(({ image, name, description, title, linkedIn }, i) => (
      <div key={name}>
        <img alt={name} src={image || 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/ff8ecd8cecbf4cdd3439b559028e0436/blank-profile-picture-973460_640.webp'}></img>
        <div>
          <h4>{name}</h4>
          {title && <div>{title}</div>}
          {description && <div style={{ maxHeight: 110 }}>{description}</div>}
          <div>
            {linkedIn && <a href={linkedIn}>
              Learn more
            </a>}
          </div>
        </div>
      </div>
    ))}
  </Profiles>
);
const Info = styled.section`
  max-width: 904px;
  margin: 4rem auto 8rem;
  padding: 0 1rem;
`;
const FAQ = styled.section`
  margin: 4rem auto 8rem;
  max-width: 640px;
  details {
    margin: 0 1rem;
    summary {
      cursor: pointer;
      span {
        margin-left: 0.5rem;
      }
    }
    > div {
      margin: 1rem 0 1rem 1.3rem;
    }
  }
`;

const TICKET = (new URLSearchParams(window.location.search)).get('ticket');

const Event = () => {
  const { account } = useConfig();
  const [register, setRegister] = useState(TICKET);
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const { query: events } = useApiQuery(EVENTS_COLLECTION, paths[3] ? [{ key: 'year', value: paths[2] }, { key: 'url', value: paths[3] }] : [{ key: '_id', value: paths[2] }], []);
  const event = events && events[0] || events;
  const { setLoading, setReachedEnd, query: guests, setQuery } = useApiQuery(GUESTS_COLLECTION, event && event._id && !event.hideGuestList && [{ key: 'event', value: event._id }, { key: 'limit', value: 2000 }], []);
  let users = [];
  for (let i = 0; i < 20; i++) {
    const ids = event && (guests || event.hideGuestList) && event.hosts && [...event.hosts.slice(i * 100, i * 100 + 100).map(({ _id }) => _id), ...(guests || []).slice(i * 100, i * 100 + 100).map(({ guest }) => guest)].filter((v) => v).join(',');
    const { query: newUsers } = useAppQuery('users', ids && [{ key: 'ids', value: ids }], []);
    users = [...users, ...(newUsers || [])];
  }
  if (!event || (!guests && !event.hideGuestList)) return null;
  const year = (new Date(event.startTime)).toLocaleString('default', { year: 'numeric' });
  const month = (new Date(event.startTime)).toLocaleString('default', { month: 'long' });
  const startDay = (new Date(event.startTime)).toLocaleString('default', { day: 'numeric' });
  const endDay = (new Date(event.endTime)).toLocaleString('default', { day: 'numeric' });
  const date = (startDay === endDay) ? `${month} ${startDay}, ${year}` : `${month} ${startDay}-${endDay}, ${year}`;
  const onClose = (registered) => {
    if (registered) {
      setQuery(null);
      setReachedEnd(false);
      setLoading(true);
    }
    setRegister('');
  }
  const currentYear = parseInt(years[0]);

  if (!event.email) {
    event.email = {
      subject: 'World Logic Day Registration Confirmed!',
      html: `<!DOCTYPE html> <html> <head> <style> .body { padding: 32px 0; margin: 0; background-color: #1ebd531a; } .container { position: relative; max-width: 460px; margin: auto; border: 8px solid #1ebd53; } .header { background-color: white; padding: 32px calc(50% - 30px) 0 calc(50% - 30px); } .header img { width: 150px; } .text { background-color: white; padding: 16px; line-height: 1.5; } .text * { text-align: center; text-decoration: none; color: black; } .footer { text-align: center; margin: 0 auto; padding: 32px; background-color: white; } .footer * { color: black; } .cta { text-align: center; background-color: #1ebd53; color: white; display: inline-block; padding: 1rem; margin: 40px auto 0px auto; font-size: 20px; font-weight: bold; text-transform: uppercase; } </style> </head> <body class="body"> <div class="container"> <div class="header"> <a href="https://logictry.com"><img style="width: 60px;" alt="Logictry" src="https://logictrystatic.s3.amazonaws.com/images/icongreen120.png" /></a> </div> <div class="text"> <h1>World Logic Day Registration Confirmed!</h1> <p>Thank you for registering. Please check the website for updates and to register for other World Logic Day events.</p> <p>You may also login and update your profile picture on your account page.</p> </div> <div class="footer"> <p>2024 Logictry, Inc, All Rights Reserved.</p> <p><a href="https://linktr.ee/logictry">Follow us on Social</a> | <a href="https://logictry.com/legal/privacy">Privacy Policy</a> | <a href="https://logictry.com/legal/terms">Terms of Service</a></p> </div> </div> </body> </html>`
    }
  }

  return (
    <Main>
      <Header
        height="calc(100vh - 140px)"
        minHeight="540px"
        cover={event.eventImage}
        backgroundSize="cover"
      >
        <h1>{event.name}</h1>
        {event.year === currentYear && <>
          <p>{date}</p>
          <CountDown endTime={moment.tz(event.startTime, event.timeZone).utc()} />
          <Register>
            <div onClick={() => setRegister('NO_TICKET_SELECTED')}>
              <div>Click to Register</div>
            </div>
            {event.externalUrl && <div onClick={() => window.open(event.externalUrl, '_blank')}>
              <div>View External Website</div>
            </div>}
            {account && account._id === event.owner && <NavLink to={`edit`}>
              <div>Click to Edit Event</div>
            </NavLink>}
            {account && account._id === event.owner && <NavLink to={`analytics`}>
              <div>Click to See Attendees</div>
            </NavLink>}
          </Register>
        </> || <>
          <p>{(new Date(event.startTime)).toLocaleString('default', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })}</p>
          <Register>
            <a href="https://logictry.com/collections/6765b722d245af2839877693" target="_blank">
              <div>See Event Videos</div>
            </a>
          </Register>
        </>}
      </Header>
      {event.tickets && event.year === currentYear && <Tickets>
        <h2>Tickets</h2>
        <div>
          {event.tickets.map((ticket) => (
            <div key={ticket.price}>
              <div>
                <h3>${ticket.price}</h3>
                <div>{ticket.description}</div>
              </div>
              {/* <div>
                <ul>
                  {ticket.items.map((item) => (
                    <li key={item.name} style={{ color: item.included ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.3)' }}>{item.name}</li>
                  ))}
                </ul>
              </div> */}
              <div onClick={() => setRegister(ticket.id || 'NO_TICKET_SELECTED')}>
                <Button>Register</Button>
              </div>
            </div>
          ))}
        </div>
      </Tickets>}
      {event.sponsors && <SponsorsAndPartners>
        <h2>Sponsors</h2>
        <div>
          {event.sponsors.map((image) => (
            <div key={image}>
              <img alt={image} src={image}></img>
            </div>
          ))}
        </div>
      </SponsorsAndPartners>}
      {event.description && (
        <Info>
          <h2>About Event</h2>
          <div dangerouslySetInnerHTML={{ __html: event.description }} />
        </Info>
      )}
      {event.hosts && event.hosts.length > 0 && <Speakers>
        <h2>Hosts</h2>
        <ProfileSection profiles={event.hosts.map((h) => {
          const user = users && users.find(({ _id }) => h._id === _id) || {};
          return {
            image: h.image || user && user.image && user.image.link,
            name: h.name || user && user.fullname,
            title: h.title,
            linkedIn: h.linkedIn
          };
        })} />
      </Speakers>}
      {event.program && event.program.length > 0 && <Program>
        <h2>Speakers</h2>
        {event.program.map((p) => <Programs key={p.title} title={p.title} description={p.description} startTime={p.startTime} endTime={p.endTime} speakers={p.speakers} />)}
      </Program>}
      {event.speakers && <Speakers>
        <h2>Speakers</h2>
        <ProfileSection profiles={event.speakers} />
      </Speakers>}
      {event.awardsCommittee && <Speakers>
        <h2>Awards Committee</h2>
        <ProfileSection profiles={event.awardsCommittee} />
      </Speakers>}
      {event.hostCommittee && <Speakers>
        <h2>Host Committee</h2>
        <ProfileSection profiles={event.hostCommittee} />
      </Speakers>}
      {event.vipPartyHosts && <Speakers>
        <h2>VIP Party Hosts</h2>
        <ProfileSection profiles={event.vipPartyHosts} />
      </Speakers>}
      {!event.hideGuestList && event.year === currentYear && <Guests>
        <h2>Attendees ({event.maxGuests ? `${guests.length + (event.externalGuests || 0)} / ${event.maxGuests}` : `${guests.length}`})</h2>
        <ProfileSection profiles={guests.map(({ guest }) => {
          const user = users && users.find(({ _id }) => guest === _id) || {};
          return {
            _id: guest,
            image: user && user.image && user.image.link,
            name: user && user.fullname,
            description: user && user.description
          };
        })} />
        <div>
          <Button onClick={() => setRegister('NO_TICKET_SELECTED')}>
            Click to Register
          </Button>
        </div>
      </Guests>}
      {event.tickets && event.year === currentYear && <Tickets>
        <h2>Tickets</h2>
        <div>
          {event.tickets.map((ticket) => (
            <div key={ticket.price}>
              <div>
                <h3>${ticket.price}</h3>
                <div>{ticket.description}</div>
              </div>
              {/* <div>
                <ul>
                  {ticket.items.map((item) => (
                    <li key={item.name} style={{ color: item.included ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.3)' }}>{item.name}</li>
                  ))}
                </ul>
              </div> */}
              <div onClick={() => setRegister(ticket.id || 'NO_TICKET_SELECTED')}>
                <Button>Register</Button>
              </div>
            </div>
          ))}
        </div>
      </Tickets>}
      <Location>
        {event.geo && <div>
          <iframe src={`https://maps.google.com/maps?q=${event.geo.coordinates[1]},${event.geo.coordinates[0]}&t=&z=15&ie=UTF8&iwloc=&output=embed`} />
        </div>}
        <div>
          <img alt={event.mapImage || 'https://logictrystatic.s3.amazonaws.com/images/earth-1756274_1920.jpg'} src={event.mapImage || 'https://logictrystatic.s3.amazonaws.com/images/earth-1756274_1920.jpg'}></img>
          <div>
            <h2>{event.location}</h2>
            <h3>{event.address}</h3>
          </div>
        </div>
      </Location>
      {event.partners && <SponsorsAndPartners>
        <h2>Partners</h2>
        <div>
          {event.partners.map((partner) => (
            <div key={partner}>
              <img alt={partner} src={partner}></img>
            </div>
          ))}
        </div>
      </SponsorsAndPartners>}
      {/* {event.faq && <FAQ>
        <h2>FAQ</h2>
        {Object.keys(event.faq).map((key: string) => (
          <details key={key}>
            <summary><span>{key}</span></summary>
            <div>{event.faq[key]}</div>
          </details>
        ))}
      </FAQ>} */}
      {register && <RegisterForm onClose={onClose} event={event} collections={{ Contacts: CONTACTS_COLLECTION, Guests: GUESTS_COLLECTION, Payments: PAYMENTS_COLLECTION }} buttonColor="#f82249" ticket={register} />}
      <Footer />
    </Main>
  )
};
export default Event;
