import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Button from "../../styledhtml/Button";

const EventsWrapper = styled.section`
  padding: 0 1rem;
  a {
    text-decoration: none;
  }
  > div {
    > a {
      text-decoration: none;
      > div {
        display: flex;
        width: 100%
        align-items: center;
        margin: 2rem 0;
        position: relative;
        border-radius: 0.5rem;
        overflow: hidden;
        max-width: 100%;
        > img {
          object-fit: cover;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          margin-right: 1rem;
          flex-shrink: 0;
        }
        div {
          display: flex;
          justify-content: center;
          flex-direction: column;
          > div {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`;
const IMAGES = {
  'United States': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/e499f1fbaeb06cf2b9f6ddfd4de672b5/united-states-of-america.png',
  'Egypt': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/659fd5bace807a06c8e58dda/066cd969838da3d5a32f8ebb37fee03c/Flag_of_Egypt.svg.png',
  'Switzerland': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/95b9ca5516f8e7b0a32a569cad6f51ab/switzerland.png',
  'Russia': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/6589577a31f8b64128266472/f3c7500b1bc275f80dbcf0b10151b7da/flag.jpg',
  'Italy': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/cc79b1867397b8aec17dccce31e6fbef/italy.png',
  'Mexico': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/57baf107abb5180dc6e99b28b8e2cee2/mexico.png',
  'France': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/78e9f99bc3c993c9c83615d0f3d028c4/france.png',
  'Spain': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/656db789fcfffa44ebc244ea/500c3f1ef42d431af46efeaf47099b8d/Bandera_de_Espan%CC%83a.svg.png',
  'Korea': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/b026a63067e5bf4be71d827a23411f07/south-korea.png',
  'Moldova': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/6574404552186174604a3099/76d1340c46bea5a09c49514e5f2e11b9/Flag_of_Moldova.svg',
  'Taiwan': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/6597f480c90a0d03f0c22969/b273455fcfe4884e97977686ec293637/download.png',
  'New Zealand': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/a1c43634845e058e4877c8d059124232/new-zealand.png',
  'Hungary': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/b9d87010669a9f5564fb3d6bb852cba7/hungary.png',
  'Austria': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/3458299dbc5399bb27822c9e489ba3c4/austria.png',
  'Poland': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/da990cdbad5e1bb78bfad08a90952e10/poland.png',
  'Canada': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/658600d2c5d1d90dbee68fe2/07d3403cd6c36f61d756233d82182e9f/Flag_of_Canada.svg.png',
  'Peru': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/655366f5afec3777f586f8e7/b4bea988e63a9731d4d5d4b99cfea41c/641929cd-e4a2-45f7-a965-5efb4194afb7.edcec4b0dfa46b6bdddfde14469df192.webp',
  'Serbia': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/659433851d4beb15cdc26da8/9d91ccc81c2562dbd2b9d50b10d7ce6a/flag-of-serbia.png',
  'Germany': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/cc23f204eaadf5d54d2e131228fec9c0/germany.png',
  'Brazil': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/657b399d6837722e73eadbab/8be19af122ef600c46d7fb953fd33e6d/br%20flag.png',
  'Croatia': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/23aa4c3f717d717c25e2ec58abdfe857/croatia.png',
  'Nigeria': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/153cbb4ef648717e35f671bf2c3fe73b/nigeria.png',
  'Turkey': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/65a21d81fef3db110244aa81/e59f846b1303962bdc58cd23e66fe339/IMG-20240113-WA0004.jpg',
  'Iran': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/659403921d4beb15cdc26d84/1b763b08a67f62a1ecaa0ca9b30d6d1b/Flag.png',
  'Sweden': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/6413fcd6562db56d77776357d84aa37d/sweden.png',
  'United Kingdom': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/6f382b2ddfdd4ea349ac9ca989da123a/united-kingdom.png',
  'Estonia': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/9c32da2c85d2bc74fb753121dd846acd/estonia.png',
  'Ukraine': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/658c5571cc950113c4729cad/3972ac958359080c596d2ff633ef8579/ua.png',
  'Kazakhstan': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/fb346a50496bb24b320ba544b0587d88/kazakhstan.png',
  'Greece': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/0cb4c4022a231291afc5016c1697acbf/Flag_of_Greece.svg.png',
  'South Africa': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/df1a1208e73b8435cc7c01c7318c95d6/south-africa.png',
  'Belgium': 'https://logictryuserfiles.s3.amazonaws.com/64de503489ba30034b5e3bd2/0868d7aa44f6ba9513187a706e9d9ab0/belgium.png',
  'Czechia': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/5cf13c4899397a33fd191007/79984b1b4681dbab2cb6100caac6351a/czech-republic.png',
  'India': 'https://logictrycompanyfiles.s3.amazonaws.com/652ec9546346c4180ae2c50b/659519ca1d4beb15cdc26ddf/9607e7b396a9ae763cf9a822eda9ab0c/Flag_of_India.svg.png',
  'Portugal': 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/91059309410d78089aea54752a3f29fa/portugal.jpg'
}

function Events(props) {
  return (
    <EventsWrapper>
      <h2>{props.title}</h2>
      <div>
        {props.events.map(({ _id, name, startTime, country, image, location, year, url, externalUrl }) => {
          if (!image && country) {
            Object.keys(IMAGES).forEach((key) => {
              if (country.startsWith(key)) image = IMAGES[key];
            });
          }
          return (
            <a href={url && `/events/${year}/${url}` || `/events/${_id}`} key={`${name}${startTime}${country}`}>
              <div>
                {image && <img alt={country} src={image} /> || <img></img>}
                <div>
                  <h3>{name}</h3>
                  <div>{(new Date(startTime)).toLocaleString()} | {country} | {location}</div>
                </div>
              </div>
            </a>
          )
      })}
      </div>
      <NavLink to={`/events/create`}>
        <Button>Add Event</Button>
      </NavLink>
    </EventsWrapper>
  )
}
export default Events;
